.hero {

  &__img {
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }

  &__content {
    bottom: 0;

    @include md-up {
      width: calc(50% - 9.6rem);
      height: 100%;
    }

    @include xl-up {
      width: 30%;
    }
  }
}