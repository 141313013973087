$hamburgerWidth: 36rem;

.header {
  overflow-y: visible;
  overflow-x: clip;

  &__inner {
    padding: 1.2rem 0;
  }
  
  $height-icon: 1rem;
  $width-line: 1.8rem;
  $height-line: 0.2rem;

  $rotation: 45deg;
  $translateY: calc($height-icon / 2);
  $translateX: 0;

  #hamburger-icon {
    width: $width-line;
    height: $height-icon;
    
    .line {
      width: $width-line;
      height: $height-line;
      left: 0;
      border-radius: calc($height-line / 2);
      transition: all 0.3s;
      
      &.line-1 {
        top: 0;
      }
      &.line-2 {
        top: 50%;
      }
      &.line-3 {
        top: 100%;
      }
    }
    &:hover, &:focus {
      .line-1 {
          transform: translateY(calc($height-line / 2 * -1));
      }
      .line-3 {
          transform: translateY(calc($height-line / 2));
      }  
    }
    &.active {
      .line-1 {
        transform: translateY($translateY) translateX($translateX) rotate($rotation);
      }
      .line-2 {
        opacity: 0;
      }
      .line-3 {
        transform: translateY(calc($translateY * -1)) translateX($translateX) rotate(calc($rotation * -1));
      }
    }
  }

  .hamburger-menu {
    bottom: 0;
    transform: translate(100%, 100%);
    z-index: 12;
    right: 0;
    width: $hamburgerWidth;
    transition: 0.3s transform ease-out;
    height: 100vh;

    &.active {
      transform: translate(-$hamburgerWidth, 100%) !important;
    }

    a {
      color: $black;

      &:hover {
        color: $cta;
      }

      svg {
        width: 1.6rem;
        height: 1.6rem;
        padding: 0.3rem;
        margin-left: 1rem;
      }
    }

    &__item {
      &.has-children {
        .hamburger-menu__submenu {
          top: 0;
          width: $hamburgerWidth;
          transform: translate(calc(#{$hamburgerWidth} - 3.2rem), 0);
          height: 100%;
          background: $white;
          color: $black;
      }
      }
    }
  }

  &.active {
    .hamburger-menu {
      transform: translate(0, 100%);
      transition: 0.3s transform ease-in;
    }
  }
}
