.notFound {

  &__img {
    aspect-ratio: 16 / 9;
    object-fit: cover;
    object-position: center;
  }

  &__content {
    svg {
      @include squaresize(2.4rem);
    }
  }
}