// Normal font-family
@font-face {
  font-family: 'Nouvelle-Grotesk-Normal';
  src:
    url('./fonts/NouvelleGrotesk-Normal.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Light font-family
@font-face {
  font-family: 'Nouvelle-Grotesk-Light';
  src:
    url('./fonts/NouvelleGrotesk-Light.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
