.news-grid {
  .news-teaser.container {
    margin-top: 0.8rem;
  }

  .news-teaser.container {
    padding: 0;
  }

  .col-md-4 {
    padding: 0 0.4rem;
  }
}