.twocolumn {

  &__inner {
    padding: $spc5 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include md-up {
      padding: $spc6 0;
      grid-template-columns: repeat(2, 1fr);
      gap: $spc3;
    }

    @include xl-up {
      padding: 0 $spc-inner-xl;
      gap: $spc6;
    }
    
    .row-reverse {
      @include md-up {
        order: -1;
      }
    }
  }

  &__img {
    width: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    object-position: center;
  }

  &__content {
    .btn{
      display: flex;
      align-items: center;
      margin: 0 auto;

      @include xl-up {
        margin: 0;
      }
    }

    p {
      margin-bottom: 2.4rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

  } 

  &__buttons {
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.4rem;
  }
}