.slider {
  &-fullscreen {
    position: absolute;
    left: 50%;
    padding-left: 1rem;
    transform: translateX(calc(50% + 2rem));
    margin-right: -4rem;
    bottom: -4rem;
    z-index: 10;
    border-left: 0.1rem $gray-300 solid;

    & svg {
      color: $cta;
    }
  }

  .swiper-pagination {
    bottom: -3.4rem;

    &-bullet {
      background-color: $white;
      border: 0.1rem solid $cta;
      opacity: 1 !important;
      position: relative;
      z-index: 1;
      width: .8rem;
      height: .8rem;

      &:last-child {
        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0.2rem;
        left: 100%;
        width: 1.4rem;
        height: .2rem;
        background-color: $cta;
      }
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $cta;
  }

  .swiper-button-disabled {
    opacity: 0.35;
  }

  .swiper-button-prev,
  .swiper-button-next {
    padding: 2.4rem;

    &:after {
      font-size: 2rem;
      padding: 2rem;
    }
  }

  &__fullscreen {
    & .slider__inner {
    position: fixed !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 3.6rem;
    }

    & .slider__fullscreen--bg {
      display: unset !important;
      position: fixed;
      top: -50vh;
      left: 0;
      width: 100%;
      height: 200vh;
      background-color: $white;
    }

    & .slider-fullscreen-open {
      display: none !important;
    }

    & .slider-fullscreen-close {
      display: unset !important;
    }
  }
}