.popup {
  position: fixed;
  top: -100%;
  left: 0;
  height: 100vh;
  width: 100%;
  transition: opacity 0.4s ease;
  opacity: 0;
  z-index: -99999;

  &-show {
    opacity: 1;
    top: 0;
    z-index: 99999;
  }
  
  &-close {
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $gray-100;
  }
  
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: $primary;
    width: 80%;
    min-height: 80vh;
    padding: 1.6rem;
    transform: translate(-50%, -50%);
  }
}
