// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           1.6rem !default;
$grid-row-columns:            6 !default;
$container-margin-sm:         2.4rem;
$container-margin:            6.4rem;

// Container padding

$container-padding-x: $grid-gutter-width !default;

// MEDIA QUERIES 
// Phones
$screen-sm: 360px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 1024px;

// Large desktops
$screen-xl: 1440px;

// Extra large screens
$screen-xxl: 1920px;



// Define the maximum width of `.container` for different screen sizes.
// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 576px,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl,
  xxl: $screen-xxl
) !default;
// scss-docs-end container-max-widths

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: $screen-sm,
  md: $screen-md,
  lg: $screen-lg,
  xl: $screen-xl,
  xxl: $screen-xxl
) !default;
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
@include _assert-ascending($container-max-widths, "$container-max-widths");



// Options
$variable-prefix:             bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1.6rem !default;
$spacers: (
  0: 0,
  1: $spacer * .5,
  2: $spacer * 1,
  3: $spacer * 1.5 ,
  4: $spacer * 2,
  5: $spacer * 3,
  6: $spacer * 4,
) !default;

// Creates gaps for the flex element
$gaps: (1: .5, 2: 1, 3: 1.5, 4:2, 5:3, 6:3.5);
@each $gap, $val in $gaps {
  .gap-#{$gap} {
    gap:  calc(#{$spacer} * #{$val}) !important;
  }
}


$gutters: (
  0: 0,
  1: $grid-gutter-width * 1,
  2: $grid-gutter-width * 1.5,
  3: $grid-gutter-width * 2,
  4: $grid-gutter-width * 2.5,
  5: $grid-gutter-width * 3,
  6: $grid-gutter-width * 3.5,
);
// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%
) !default;
// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-text-align:           null !default;

// Links

$link-decoration:                         underline !default;
$link-hover-decoration:                   null !default;
$stretched-link-pseudo-element:           after !default;
$stretched-link-z-index:                  1 !default;


// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.


// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width:                1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;

$border-style:                solid !default;

// scss-docs-end border-variables

$hr-margin-y:                 $spacer !default;
$hr-color:                    inherit !default;

// fusv-disable
$hr-height:                   null !default; // Deprecated in v5.2.0
// fusv-enable

$hr-border-color:             null !default; // Allows for inherited colors
$hr-border-width:             $border-width !default;
$hr-opacity:                  .25 !default;

$legend-margin-bottom:        .5rem !default;
$legend-font-size:            1.5rem !default;
$legend-font-weight:          null !default;

$list-inline-padding:         .5rem !default;

$mark-padding:                .1875em !default;
// scss-docs-end type-variables


// Spasings

$spc-base: 0.8rem !default;

$spc0: calc($spc-base * 0) !default;  // 0px
$spc1: calc($spc-base * 1) !default;  // 8px
$spc2: calc($spc-base * 2) !default;  // 16px
$spc3: calc($spc-base * 3) !default;  // 24px
$spc4: calc($spc-base * 4) !default;  // 32px
$spc5: calc($spc-base * 5) !default;  // 40px
$spc6: calc($spc-base * 6) !default;  // 48px
$spc7: calc($spc-base * 7) !default;  // 56px
$spc8: calc($spc-base * 8) !default;  // 64px
$spc9: calc($spc-base * 9) !default;  // 72px
$spc10: calc($spc-base * 10) !default;// 80px
$spc12: calc($spc-base * 12) !default;// 96px
$spc14: calc($spc-base * 14) !default;// 112px
$spc16: calc($spc-base * 16) !default;// 128px
$spc18: calc($spc-base * 18) !default;// 144px
$spc20: calc($spc-base * 20) !default;// 160px

$spc-inner-sm: 4rem !default;
$spc-inner-lg: 7.8rem !default;
$spc-inner-xl: 11.6rem !default;


